"use client";

import { useEffect, useRef } from "react";

import { useEventTracking } from "./EventProvider";
import { usePathname, useSearchParams } from "next/navigation";

interface PageViewProps {
  tags: string[];
}

export const PageView = ({ tags }: PageViewProps) => {
  const pathname = usePathname();
  const sp = useSearchParams();
  const { analytics } = useEventTracking();
  useEffect(() => {
    analytics.page();
  }, [analytics, pathname, sp]);

  return null;
};
